#sippp {
  background-color: #CDABF8 !important;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: infinite 20s linear; /* App-logo-spin */
  }
}

#sippp > .container {
  min-height: 100vh;
  background-color: #CDABF8 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #042431;
}

.splash {
  justify-content: center;
  padding: 0 20px 0 20px;
}

.content {
  padding: 100px 20px 50px 20px;
}

.link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 0;
}

.button {
  background-color: #450291;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  margin: 0 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.button:hover {
  background-color: #5903b8;
}

.button.disabled {
  background-color: gray;
  cursor: not-allowed;
  pointer-events: none;
}

.button.disabled:hover {
  background-color: gray;
}

.about-logo {
  height: 10vmin;
  position: absolute;
  top: 10px;
  left: 10px;
}

h1 {
  font-size: 3em;
  text-align: center;
  max-width: 768px;
  margin: 30px auto;
}

h2 {
  margin-top: 20px;
  font-size: 2em;
  text-align: center;
  max-width: 768px;
  margin: 20px auto;
}

h3 {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  max-width: 768px;
  margin: 20px auto;
}

p {
  font-size: 1.5em;
  color: #333;
  max-width: 768px;
  margin: 15px auto;
  text-align: justify;
}

li {
  font-size: 1.5em;
  color: #333;
}

img {
  max-width: 374px;
}